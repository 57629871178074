<template>
    <div>
        <header class="block-header">Uren toevoegen</header>
        <div id="hour-form" :class="submitting ? 'opaque' : ''">
            <FormulateForm
                v-model="formValues"
                name="hourForm"
                @submit="submitForm"
            >
                <FormulateInput
                    type="radio"
                    label="Dag"
                    :options="dayOptions"
                    name="day"
                    tabindex="1"
                    v-model="hour.day"
                    validation="required"
                    outer-class="radiogroup"
                />

                <FormulateInput
                    type="time"
                    label="Starttijd"
                    name="starttime"
                    v-model="hour.starttime"
                    validation="required"
                    outer-class="timepicker"
                />

                <FormulateInput
                    type="radio"
                    label="Duur"
                    :options="durationOptions"
                    name="duration"
                    tabindex="1"
                    v-model="hour.duration"
                    validation="required"
                    :outer-class="['radiogroup', 'multiradio']"
                />

                <FormulateInput
                    type="submit"
                    tabindex="7"
                    :disabled="submitDisabled"
                    outer-class="submitbutton"
                >
                    <span><i v-if="!submitting" class="fa fa-floppy-o"></i> Opslaan <i v-if="submitting" class="fa fa-spinner fa-spin"></i></span>
                </FormulateInput>

            </FormulateForm>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Notyf } from 'notyf'; // For error notifications
import 'notyf/notyf.min.css';
import errorHandler from '@/libraries/error-handler'

export default {
    name: 'CreateHourRecord',
    data() {
        return {
            hour: {
                day: 'today',
                starttime: (new Date()).toLocaleTimeString('nl-NL', {
                    hour: '2-digit',
                    minute: '2-digit'
                }),
                duration: '15'
            },
            dayOptions: {
                today: 'Vandaag',
                yesterday: 'Gisteren'
            },
            durationOptions: {
                '15': '15',
                '20': '20',
                '30': '30',
                '60': '60',
            },
            formValues: {},
            submitDisabled: false,
            submitting: false,
            notyf: new Notyf()
        };
    },
    methods: {
        ...mapActions('hour', ['postData']),
        submitForm() {
            this.submitting = true;
            this.submitDisabled = true
            this.postData(this.formValues)
            .then(this.showSuccessMessageAndReset)
            .catch(err => {
                const {formErrors: issues} = errorHandler(err)
                this.notyf.error(issues.join(','))
            })
            .finally(() => {
                this.submitting = false
                this.submitDisabled = false
            })
        },
        resetFormStateToDefault() {
            this.hour = {
                day: 'today',
                starttime: (new Date()).toLocaleTimeString('nl-NL', {
                    hour: '2-digit',
                    minute: '2-digit'
                }),
                duration: '15'
            }
        },
        showSuccessMessageAndReset() {
            this.notyf.success('Overleg toegevoegd!')
            this.resetFormStateToDefault()
        },
    },
}
</script>

<style lang="less">
#hour-form.opaque {
    opacity: 0.5;
}

.radiogroup {
    height: 48px;
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;
    background: @background1;

    &> .formulate-input-wrapper {
        display: grid;
        grid-template-columns: 70px 1fr;

        &> label {
            padding-left: 6px;
        }

        .formulate-input-group {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .formulate-input-group-item > .formulate-input-wrapper {
                display: grid;
                grid-template-columns: 25px 1fr;

                /* Custom radio button */
                .formulate-input-element--radio {
                    input {
                        border: 0;
                        clip: rect(0 0 0 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }

                    input ~ label {
                        top: 0;
                    }

                    input ~ label:before {
                        font-family: FontAwesome;
                        display: inline-block;
                        letter-spacing: 10px;
                        font-size: 20px;
                        color: @primary;
                        width: 1.4em;
                        position: relative;
                        top: 2px;
                    }

                    input:focus ~ label:before {
                        color: @primary;
                    }

                    input[type=radio] ~ label:before, input[type=radio]:checked ~ label:before {
                        font-size: 22px;
                        top: 1px;
                    }

                    input[type=radio] ~ label:before {
                        content: "\f10c";
                    }

                    input[type=radio]:checked ~ label:before {
                        content: "\f058";
                    }
                }
            }
        }
    }

    &.multiradio > .formulate-input-wrapper {
        .formulate-input-group {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    label {
        margin-bottom: 0;
        position: relative;
        top: 5px;
    }
}

.timepicker {
    height: 48px;
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;

    &> .formulate-input-wrapper {
        display: grid;
        grid-template-columns: 70px 1fr;

        &> label {
            margin-bottom: 0;
            align-self: center;
        }

        .formulate-input-element--time {
            border-bottom: 1px solid @primary;
            input {
                border: none;
                width: 100%;
                padding-right: 6px;
            }
        }
    }
    
}

.submitbutton {
    button[type="submit"] {
        margin-top: 24px;
        background: @primary;
        border: none;
        color: white;
        height: 48px;
        padding: 12px 0;
        width: 100%;
    }
}

ul.formulate-form-errors {
    margin-top: 1rem;
    list-style: none;   
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#submit-message {
    margin-top: 24px;
    text-align: center;
}
</style>