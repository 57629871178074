<template>
    <div>
        <header class="block-header">Todo item toevoegen</header>
        <div id="todo-form" :class="submitting ? 'opaque' : ''">
            <FormulateForm
                v-model="formValues"
                name="todoForm"
                @submit="submitForm"
            >
                <FormulateInput
                    type="text"
                    v-model="todo.description"
                    name="description"
                    label="Wat"
                    placeholder="Omschrijving"
                    validation="required"
                />

                <FormulateInput
                    v-model="todo.customer_id"
                    :options="customerOptions"
                    type="select"
                    name="customer_id"
                    placeholder="Selecteer een klant"
                    label="Klant"
                    outer-class="formulate-input bg-green"
                />

                <FormulateInput
                    id="project"
                    v-model="todo.project_id"
                    :options="projectOptions"
                    type="select"
                    name="project_id"
                    placeholder="Select een project"
                    label="Project"
                />

                <FormulateInput
                    type="radio"
                    label="Wie"
                    :options="userOptions"
                    name="assigned_user"
                    tabindex="1"
                    v-model="todo.assigned_user"
                    validation="required"
                    outer-class="radiogroup"
                />

                <FormulateInput
                    type="date"
                    label="Datum"
                    name="deadline"
                    v-model="todo.deadline"
                    validation="required"
                    outer-class="datepicker"
                />

                <FormulateInput
                    type="radio"
                    label="Uren"
                    :options="timeOptions"
                    name="time_estimate"
                    tabindex="1"
                    v-model="todo.time_estimate"
                    validation="required"
                    :outer-class="['radiogroup', 'multiradio']"
                />

                <FormulateInput
                    type="submit"
                    tabindex="7"
                    :disabled="submitDisabled"
                    outer-class="submitbutton"
                >
                    <span><i v-if="!submitting" class="fa fa-floppy-o"></i> Opslaan <i v-if="submitting" class="fa fa-spinner fa-spin"></i></span>
                </FormulateInput>

            </FormulateForm>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { Notyf } from 'notyf'; // For error notifications
import 'notyf/notyf.min.css';
import errorHandler from '@/libraries/error-handler'

export default {
    name: 'CreateTodoItem',
    data() {
        return {
            todo: {
                description: '',
                customer_id: null,
                project_id: null,
                assigned_user: null,
                time_estimate: '1',
                deadline: dayjs().format('YYYY-MM-DD'),
            },
            userOptions: {
                '0': 'Onbepaald',
                '1': 'Leonie',
                '2': 'Pim',
                '100': 'Samen',
            },
            timeOptions: [
                {
                    value: '0.25', label: '15\'',
                },
                {
                    value: '0.33', label: '20\'',
                },
                {
                    value: '0.5', label: '30\'',
                },
                {
                    value: '1', label: '60\'',
                },
                {
                    value: '2', label: '2',
                },
                {
                    value: '3', label: '3',
                },
                {
                    value: '4', label: '4',
                },
                {
                    value: '8', label: '8',
                },
            ],
            formValues: {},
            submitDisabled: false,
            submitting: false,
            notyf: new Notyf(),
            rawCustomerData: null,
        };
    },
    computed: {
        ...mapGetters('user', ['userId']),
        customerOptions() {
            if (this.rawCustomerData) {
                const customerList = this.rawCustomerData.map(item => ({
                    label: item.name,
                    value: item.id
                })) 
                return customerList
            }
            return null
        },
        projectOptions() {
            const projectList = {}
            if (this.rawCustomerData && parseInt(this.todo.customer_id) > 0) {
                const customer = this.rawCustomerData.find(customer => customer.id === parseInt(this.todo.customer_id))
                if (customer) {
                    customer.projects
                        .filter(project => project.finished === 0)
                        .forEach(project => projectList[project.id] = project.name)
                    return projectList
                }
            }
            return null
        },
    },
    methods: {
        ...mapActions('todos', ['storeTodo', 'getCustomerAndProjectList']),
        submitForm() {
            this.submitting = true;
            this.submitDisabled = true
            this.storeTodo(this.formValues)
            .then(this.showSuccessMessageAndReset)
            .catch(err => {
                const {formErrors: issues} = errorHandler(err)
                this.notyf.error(issues.join(','))
            })
            .finally(() => {
                this.submitting = false
                this.submitDisabled = false
            })
        },
        resetFormStateToDefault() {
            this.todo = {
                description: ' ',
                customer_id: null,
                project_id: null,
                assigned_user: this.userId,
                time_estimate: '1 uur',
                deadline: dayjs().format('YYYY-MM-DD'),
            }
        },
        showSuccessMessageAndReset() {
            this.notyf.success('Actiepunt toegevoegd!')
            this.resetFormStateToDefault()
        },
    },
    mounted() {
        this.todo.assigned_user = this.userId

        this.getCustomerAndProjectList()
        .then(({data}) => this.rawCustomerData = data)
        .catch(err => console.log(err))
    },
}
</script>

<style lang="less">
#todo-form.opaque {
    opacity: 0.5;
}

.formulate-input[data-classification="text"] {
    height: 48px;
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;

    &> .formulate-input-wrapper {
        display: grid;
        grid-template-columns: 70px 1fr;

        &> label {
            padding-left: 6px;
            margin-bottom: 0;
            align-self: center;
        }

        .formulate-input-element--text {
            border-bottom: 1px solid @primary;
            input {
                border: none;
                width: 100%;
                padding-right: 6px;
            }
        }
    }
}

.formulate-input[data-classification="select"] {
    height: 48px;
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;

    &.bg-green {
        background: @background1;

        &> .formulate-input-wrapper .formulate-input-element--select select {
            background: @background1;
        }
    }

    &> .formulate-input-wrapper {
        display: grid;
        grid-template-columns: 70px 1fr;

        &> label {
            padding-left: 6px;
            margin-bottom: 0;
            align-self: center;
        }

        .formulate-input-element--select select {
            width: calc(100% - 6px);
            border: none;
            border-bottom: 1px solid @primary;
            height: 40px;
            background: white;
        }
    }
}

.radiogroup {
    height: 72px;
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;
    background: @background1;

    &> .formulate-input-wrapper {
        display: grid;
        grid-template-columns: 70px 1fr;

        &> label {
            padding-left: 6px;
        }

        .formulate-input-group {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .formulate-input-group-item > .formulate-input-wrapper {
                display: grid;
                grid-template-columns: 25px 1fr;

                /* Custom radio button */
                .formulate-input-element--radio {
                    input {
                        border: 0;
                        clip: rect(0 0 0 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }

                    input ~ label {
                        top: 0;
                    }

                    input ~ label:before {
                        font-family: FontAwesome;
                        display: inline-block;
                        letter-spacing: 10px;
                        font-size: 20px;
                        color: @primary;
                        width: 1.4em;
                        position: relative;
                        top: 2px;
                    }

                    input:focus ~ label:before {
                        color: @primary;
                    }

                    input[type=radio] ~ label:before, input[type=radio]:checked ~ label:before {
                        font-size: 22px;
                        top: 1px;
                    }

                    input[type=radio] ~ label:before {
                        content: "\f10c";
                    }

                    input[type=radio]:checked ~ label:before {
                        content: "\f058";
                    }
                }
            }
        }
    }

    &.multiradio > .formulate-input-wrapper {
        .formulate-input-group {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    label {
        margin-bottom: 0;
        position: relative;
        top: 5px;
    }
}

.datepicker {
    height: 48px;
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;

    &> .formulate-input-wrapper {
        display: grid;
        grid-template-columns: 70px 1fr;

        &> label {
            padding-left: 6px;
            margin-bottom: 0;
            align-self: center;
        }

        .formulate-input-element--date {
            border-bottom: 1px solid @primary;
            input {
                border: none;
                width: 100%;
                padding-right: 6px;
            }
        }
    }
}

.submitbutton {
    button[type="submit"] {
        margin-top: 24px;
        background: @primary;
        border: none;
        color: white;
        height: 48px;
        padding: 12px 0;
        width: 100%;
    }
}

ul.formulate-form-errors {
    margin-top: 1rem;
    list-style: none;   
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#submit-message {
    margin-top: 24px;
    text-align: center;
}
</style>