const errorHandler = error => {
    const response = {
        inputErrors: {},
        formErrors: [],
    }
    if (error.response && error.response.status) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 422) {
            response.inputErrors = error.response.data.errors // assign field errors
            response.formErrors = ['Uw invoer is nog niet in orde'];
        } else if (error.response.status === 403) {
            response.formErrors = ['Deze actie is ongeldig'];
        } else if (error.response.status === 404) {
            response.formErrors = ['404, deze route bestaat niet'];
        } else if (error.response.status === 500) {
            response.formErrors = [`Oeps, een bug in de PLint App: ${error.response.data.message}`];
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
        response.formErrors = ['Oeps, een bug in de PLint App'];
    } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
        response.formErrors = ['Oeps, een bug in de PLint App'];
    }
    return response
}

export { errorHandler as default };