<template>
    <div>
        <header id="pick-your-type" class="block-header">
            Kies type: 
            <div class="custom-input inline">
                <input id="input_hour" type="radio" class="with-font" v-model="newItemType" value="hour">
                <label for="input_hour">Hour</label>
            </div>

            <div class="custom-input inline">
                <input id="input_todo" type="radio" class="with-font" v-model="newItemType" value="todo">
                <label for="input_todo">Todo</label>
            </div>
        </header>

        <CreateHourRecord v-if="newItemType === 'hour'"/>
        <CreateTodoItem v-else />
    </div>
</template>

<script>
import CreateHourRecord from '../components/CreateHourRecord'
import CreateTodoItem from '../components/CreateTodoItem'

export default {
    name: 'AddNewItem',
    components: {
        CreateHourRecord,
        CreateTodoItem,
    },
    data() {
        return {
            newItemType: 'todo',
        }
    },
}
</script>

<style lang="less" scoped>
#pick-your-type {
    margin-bottom: 48px;
}

/* Custom radiobuttons and checkboxes */
div.custom-input.inline {
    display: inline;
}

div.custom-input label {
    font-size: 14px;
    margin-right: 10px;
}

/* General icon styling */
div.custom-input input[type=radio].with-font,
div.custom-input input[type=checkbox].with-font {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* Unchecked icon styling */
div.custom-input input[type=radio].with-font ~ label:before,
div.custom-input input[type=checkbox].with-font ~ label:before {
    font-family: FontAwesome;    
    display: inline-block;
    content: "\f111";
    letter-spacing: 10px;
    font-size: 17px;
    color: @primary;
    width: 1.4em;
    position: relative;
    top: 2px;
    margin-right: 2px;
}

/* Checked radio icon styling */
div.custom-input input[type=radio].with-font:checked ~ label:before {
    content: "\f058";
    font-weight: 900;
}

/* Update icon for checkbox (unchecked) */
div.custom-input input[type=checkbox].with-font ~ label:before {
    content: "\f0c8";
    top: 2px;
}

/* Update icon for checkbox (checked) */
div.custom-input input[type=checkbox].with-font:checked ~ label:before {
    content: "\f14a";
    font-weight: 900;
}

/* Focussed state */
div.custom-input input[type=radio].with-font:focus ~ label:before,
div.custom-input input[type=checkbox].with-font:focus ~ label:before
{
    color: @primary;
}
</style>